import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

export default new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/home'
        },

        {
            path: '/',
            component: () =>
                import(
                    /* webpackChunkName: "routes" */
                    `@/components/Layout/Layout.vue`
                ),
            children: [
                //admin pages
                {
                    path: '/tenants',
                    meta: {title: 'Tenant Management'},
                    component: () => import(
                            /* webpackChunkName: "routes" */
                            `@/views/Admin/Tenants/Tenants.vue`
                        )
                },
                {
                    path: '/tenants/:id',
                    meta: { title: 'Tenant' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Admin/Tenants/Edit.vue`
                        )
                },
                {
                    path: '/featureflags',
                    meta: { title: 'Feature Flags' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Admin/FeatureFlags/FeatureFlags.vue`
                        )
                },
                {
                    path: '/featureflags/:id',
                    meta: { title: 'Feature Flag' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Admin/FeatureFlags/Edit.vue`
                        )
                },
                {
                    path: '/demos',
                    meta: { title: 'Demos' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Demo/Demos.vue`
                        )
                },
                
                // Store pages
                {
                    path: '/pagelayout',
                    meta: { title: 'Page Layout' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Demo/PageLayout.vue`
                        )
                },
                {
                    path: '/home',
                    meta: { title: 'Home' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Home/Home.vue`
                        )
                },
                {
                    path: '/error/Unauthorized',
                    meta: { title: 'Access denied' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Error/Unauthorized.vue`
                        )
                },
                {
                    path: '/reports',
                    meta: { title: 'Report Templates' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Reports/Reports.vue`
                        )
                },
                {
                    path: '/archivedreports',
                    meta: { title: 'Archived Reports' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Home/Home.vue`
                        )
                },
                {
                    path: '/reports/transactions',
                    meta: { title: 'Transaction Search' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Reports/Transactions.vue`
                        )
                },
                // Dashboard Page
                {
                    path: '/dashboard',
                    meta: { title: 'Dashboard' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Dashboard/Dashboard.vue`
                        )
                },
                {
                    path: '/products',
                    meta: { title: 'Products' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Products/Products.vue`
                        )
                },
                {
                    path: '/products/tx/:id',
                    meta: { title: 'Book' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Products/EditBook.vue`
                        )
                },
                {
                    path: '/products/gm/:id',
                    meta: { title: 'General Merchandise' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Products/GMWorkFlow/EditGM.vue`
                        )
                },
                {
                    path: '/stockadjustmentreport',
                    meta: { title: 'Stock Adjustment Report' },
                    component: () =>
                        import(
                            /* webpackChunkName: 'routes' */
                            `@/views/StockAdjustment/Report.vue`
                        )
                },
                {
                    path: '/producttransfers',
                    meta: { title: 'Product Transfers' },
                    component: () =>
                        import(
                            /* webpackChunkName: 'routes' */
                            `@/views/ProductTransfers/ProductTransfers.vue`
                        )
                },

                {
                    path: '/producttransfers/:id',
                    meta: { title: 'Product Transfers' },
                    name: 'producttransfers',
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/ProductTransfers/Edit.vue`
                        )
                },
                {
                    path: '/producttransfers',
                    meta: { title: 'Product Transfers' },
                    component: () =>
                        import(
                            /* webpackChunkName: 'routes' */
                            `@/views/ProductTransfers/ProductTransfers.vue`
                        )
                },

                {
                    path: '/purchaseorders',
                    meta: { title: 'Purchase Orders' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/PurchaseOrders/PurchaseOrders.vue`
                        )
                },
                {
                    path: '/purchaseorders/:id',
                    meta: { title: 'Purchase Order' },
                    name: 'purchaseorders',
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/PurchaseOrders/Edit.vue`
                        )
                },
                {
                    path: '/invoices',
                    meta: { title: 'Invoices' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Invoices/Invoices.vue`
                        )
                },
                {
                    path: '/invoices/:id',
                    meta: { title: 'Invoice' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Invoices/Edit.vue`
                        )
                },
                {
                    path: '/creditrequests',
                    meta: { title: 'Returns' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CreditRequests/CreditRequests.vue`
                        )
                },
                {
                    path: '/creditrequests/:id',
                    meta: { title: 'Returns' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CreditRequests/Edit.vue`
                        )
                },
                {
                    path: '/buybackbuyers',
                    meta: { title: 'Buyback - Buyers' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/Buyers.vue`
                        )
                },
                {
                    path: '/buybackpurchasers',
                    meta: { title: 'Buyback - Purchasers' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/Purchasers.vue`
                        )
                },
                {
                    path: '/buybacksessions',
                    meta: { title: 'Buyback Session Search' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/Sessions.vue`
                        )
                },
                {
                    path: '/buybacksessions/:id',
                    meta: { title: 'Buyback Session Setup' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/SessionSetup.vue`
                        )
                },
                {
                    path: '/buybacklists',
                    meta: { title: 'Buyback List Search' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/BuybackList.vue`
                        )
                },
                {
                    path: '/buybacklists/:sessionid/:purchaserid',
                    meta: { title: 'Buyback List Setup' },
                    props: true,
                    name: 'buybacklistsetup',
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/BuybackListSetup.vue`
                        )
                },
                {
                    path: '/buybacklists/:buybacklistid',
                    meta: { title: 'Buyback List Setup' },
                    props: true,
                    name: 'buybacklist',
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/BuybackListSetup.vue`
                        )
                },
                {
                    path: '/buybooks/:id',
                    meta: { title: 'Buy Books' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/BuyBooks.vue`
                        )
                },

                {
                    path: '/wantlists',
                    meta: { title: 'Want Lists' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WantLists/WantLists.vue`
                        )
                },
                {
                    path: '/wantlists/:id',
                    meta: { title: 'Want List' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WantLists/Edit.vue`
                        )
                },
                {
                    path: '/purchaseorderautogen',
                    meta: { title: 'Auto Gen' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/AutoGenerate/Autogen.vue`
                        )
                },
                {
                    path: '/purchaseorderautogen/:id',
                    meta: { title: 'Auto-Gen Record' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/AutoGenerate/Edit.vue`
                        )
                },
                {
                    path: '/wholesale',
                    meta: { title: 'Sale To Wholesale' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Wholesale/Wholesales.vue`
                        )
                },
                {
                    path: '/wholesale/:id',
                    meta: { title: 'Sale To Wholesale' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Wholesale/Edit.vue`
                        )
                },
                {
                    path: '/giftcards',
                    meta: { title: 'Gift Cards' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/GiftCards/GiftCards.vue`
                        )
                },
                {
                    name: 'editgiftcards',
                    path: '/giftcards/:id',
                    meta: { title: 'Gift Cards' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/GiftCards/Edit.vue`
                        )
                },
                {
                    path: '/accountreceivable',
                    meta: { title: 'AR Account Management' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/AccountReceivable/AccountReceivable.vue`
                        )
                },
                {
                    name: 'editaccountreceivable',
                    path: '/accountreceivable/:id',
                    meta: { title: 'AR Account Management' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/AccountReceivable/Edit.vue`
                        )
                },
                {
                    path: '/customers',
                    meta: { title: 'Customers' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Customers/Customers.vue`
                        )
                },
                {
                    path: '/customers/:id',
                    meta: { title: 'Customer' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Customers/Edit.vue`
                        )
                },
                {
                    path: '/instructors',
                    meta: { title: 'Instructors', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Instructors/Instructors.vue`
                        )
                },
                {
                    path: '/instructors/:id',
                    meta: { title: 'Instructor', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Instructors/Edit.vue`
                        )
                },
                {
                    path: '/campuses',
                    meta: { title: 'Campuses', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Campuses/Campuses.vue`
                        )
                },
                {
                    path: '/campuses/:id',
                    meta: { title: 'Campus', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Campuses/Edit.vue`
                        )
                },
                {
                    path: '/terms',
                    meta: { title: 'Terms', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Terms/Terms.vue`
                        )
                },
                {
                    path: '/terms/:id',
                    name: 'editterm',
                    meta: { title: 'Term', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Terms/Edit.vue`
                        )
                },
                {
                    path: '/suppliers',
                    meta: { title: 'Suppliers' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Suppliers/Suppliers.vue`
                        )
                },
                {
                    path: '/suppliers/:id',
                    meta: { title: 'Supplier' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Suppliers/Edit.vue`
                        )
                },
                {
                    path: '/academicdepartments/',
                    meta: { title: 'Academic Departments', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CourseDepartments/CourseDepartments.vue`
                        )
                },
                {
                    path: '/academicdepartments/:id',
                    meta: { title: 'Academic Department', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CourseDepartments/Edit.vue`
                        )
                },
                {
                    path: '/users/',
                    meta: { title: 'Users' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Users/Users.vue`
                        )
                },
                {
                    path: '/users/:id',
                    meta: { title: 'User' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Users/Edit.vue`
                        )
                },
                {
                    path: '/storeSetup',
                    meta: { title: 'Store Setup' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/StoreSetup/Edit.vue`
                        )
                },
                {
                    path: '/imports',
                    meta: { title: 'Imports' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Imports/Imports.vue`
                        )
                },
                {
                    path: '/dataexchange',
                    meta: { title: 'Data Exchange'},
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/DataExchange/DataExchange.vue`
                        )
                },
                {
                    path: '/dataexchange/log',
                    meta: { title: 'Data Exchange Log'},
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/DataExchange/DataExchangeLog.vue`
                        )
                },
                {
                    path: '/dataexchange/:id',
                    meta: { title: 'Data Exchange' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/DataExchange/Edit.vue`
                        )
                },
                {
                    path: '/dccs',
                    meta: { title: 'Departments, Classes, and Categories' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Dccs/Dccs.vue`
                        )
                },
                {
                    path: '/salesevents',
                    meta: { title: 'Sales Events'},
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/SalesEvents/SalesEvents.vue`
                        )
                },
                {
                    path: '/salesevents/:id',
                    meta: { title: 'Sales Events' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/SalesEvents/Edit.vue`
                        )
                },
                {
                    path: '/buyersGuide',
                    meta: {
                        title: 'Buyers Guide'
                    },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/BuyersGuide/Edit.vue`
                        )
                },
                {
                    path: '/managePOS',
                    meta: {
                        title: 'Manage POS'
                    },
                    props: true
                },
                {
                    path: '/sequences',
                    meta: { title: 'Sequences' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Sequences/Sequences.vue`
                        )
                },
                {
                    path: '/sequences/:id',
                    meta: { title: 'Sequences' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Sequences/Edit.vue`
                        )
                },
                {
                    path: '/courses',
                    meta: { title: 'Courses', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Courses/Courses.vue`
                        )
                },
                {
                    path: '/courses/:id',
                    meta: { title: 'Course', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Courses/Edit.vue`
                        )
                },
                {
                    path: '/courserequests',
                    meta: { title: 'Course Requests', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CourseRequests/CourseRequests.vue`
                        )
                },
                {
                    path: '/productvariantgroups',
                    meta: { title: 'Variant Groups' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/VariantGroups/VariantGroups.vue`
                        )
                },
                {
                    path: '/productvariantgroup/:id',
                    meta: { title: 'Variant Groups' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/VariantGroups/Edit.vue`
                        )
                },
                {
                    path: '/productvariants',
                    meta: { title: 'Product Variants' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/ProductVariants/ProductVariants.vue`
                        )
                },
                {
                    path: '/productvariant/:id',
                    meta: { title: 'Product Variants' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/ProductVariants/Edit.vue`
                        )
                },
                {
                    path: '/physicalinventory',
                    meta: { title: 'Physical Inventory' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/PhysicalInventory/PhysicalInventory.vue`
                        )
                },
                {
                    path: '/physicalinventory/:id',
                    meta: { title: 'Physical Inventory' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/PhysicalInventory/Edit.vue`
                        )
                },
                {
                    name: 'workcourserequests',
                    path: '/courserequests/work/:id?',
                    meta: { title: 'Course Request', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CourseRequests/Edit.vue`
                        )
                },
                {
                    name: 'editcourserequest',
                    path: '/courserequests/:id',
                    meta: { title: 'Course Request', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/CourseRequests/Edit.vue`
                        )
                },
                {
                    path: '/orderdecisions',
                    meta: { title: 'Order Decisions', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/OrderDecisions/OrderDecisions.vue`
                        )
                },
                {
                    path: '/buyersguidesearch',
                    meta: { title: 'Buyers Guide', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/BuyersGuide/BuyersGuideSearch.vue`
                        )
                },
                {
                    path: '/rentalmanagement',
                    meta: { title: 'Rental Management', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/RentalManagement/RentalManagement.vue`
                        )
                },
                {
                    name: 'workorderdecisions',
                    path: '/orderdecisions/work/:id?',
                    meta: { title: 'Order Decisions', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/OrderDecisions/Edit.vue`
                        )
                },
                {
                    name: 'editorderdecisions',
                    path: '/orderdecisions/:id',
                    meta: { title: 'Order Decision', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/OrderDecisions/Edit.vue`
                        )
                },
                {
                    name: 'buybooks',
                    path: '/buybooks',
                    meta: { title: 'Buy Books' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Buyback/BuyBookSessions.vue`
                        )
                },
                {
                    name: 'taxrates',
                    path: '/taxrates',
                    meta: { title: 'Tax Rates' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Taxes/TaxRates.vue`
                        )
                },
                {
                    name: 'weborders',
                    path: '/weborders',
                    meta: { title: 'Web Orders', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/WebOrders.vue`
                        )
                },
                {
                    name: 'editweborders',
                    path: '/weborders/:id',
                    meta: { title: 'Web Orders', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/Edit.vue`
                        )
                },
                {
                    name: 'shippingcodes',
                    path: '/shippingcodes',
                    meta: { title: 'Shipping Codes', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/ShippingCodes/ShippingCodes.vue`
                        )
                },
                {
                    name: 'editshippingcodes',
                    path: '/shippingcodes/:id',
                    meta: { title: 'Shipping Codes', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/ShippingCodes/Edit.vue`
                        )
                },
                {
                    path: '/webCatalogs',
                    meta: { title: 'Web Catalogs', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/WebCatalogs.vue`
                        )
                },
                {
                    name: '/editwebcatalogs',
                    path: '/webcatalogs/:id',
                    props: true,
                    meta: { title: 'Web Catalogs', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/EditWebCatalogs.vue`
                        )
                },
                {
                    path: '/webcataloggroups',
                    meta: { title: 'Web Catalog Groups', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/WebCatalogGroups.vue`
                        )
                },
                {
                    name: 'editwebcataloggroups',
                    path: '/webcataloggroups/:id',
                    meta: { title: 'Web Catalog Groups', singleLocationFilter: true },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/EditWebCatalogGroups.vue`
                        )
                },
                {
                    name: 'websetup',
                    path: '/websetup',
                    meta: { title: 'Web Setup', singleLocationFilter: true },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/WebOrders/WebSetup.vue`
                        )
                },
                {
                    path: '/stockAdjustmentReasons',
                    meta: { title: 'Stock Adjustment Reasons' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Accounting/StockAdjustmentReasons.vue`
                        )
                },
                {
                    path: '/stockAdjustmentReason/:id',
                    meta: { title: 'Stock Adjustment Reasons' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Accounting/Edit.vue`
                        )
                },
                {
                    path: '/invoiceAdjustmentReasons',
                    meta: { title: 'Invoice Adjustment Reasons' },
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Accounting/InvoiceAdjustmentReasons.vue`
                        )
                },
                {
                    path: '/invoiceAdjustmentReason/:id',
                    meta: { title: 'Invoice Adjustment Reasons' },
                    props: true,
                    component: () =>
                        import(
                            /* webpackChunkName: "routes" */
                            `@/views/Accounting/EditInvoiceAdjustmentReason.vue`
                        )
                }
]
        },
        //oidc callback from sso
        {
            path: '/signin-oidc',
            name: 'oidcCallback',
            component: () =>
                import(
                    /* webpackChunkName: "routes" */
                    `@/components/OidcCallback`
                ),
            meta: { allowAnonymous: true, title: 'Signing In' }
        },
        // Not found route
        {
            path: '*',
            redirect: '/'
        }
    ]
});
